import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Gallery from "./gallery/Gallery"
import Fade from "react-reveal/Fade"
import styles from "./LaserContent.module.scss"
import Videos from "./gallery/Videos"

const getLaser = graphql`
  query {
    allContentfulLaser {
      edges {
        node {
          laserDesc {
            json
          }
          large: laserImages {
            title
            fixed(width: 1200, quality: 65) {
              ...GatsbyContentfulFixed
              src
              width
              height
            }
          }
          thumbs: laserImages {
            title
            fluid(maxWidth: 500, quality: 65) {
              ...GatsbyContentfulFluid
              src
            }
          }
          videa {
            file {
              url
            }
          }
        }
      }
    }
  }
`

const LaserContent = () => {
  const data = useStaticQuery(getLaser).allContentfulLaser.edges[0].node
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Fade bottom duration={700}>
          <p className="text">{children}</p>
        </Fade>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Fade bottom duration={700}>
          <h3 className={styles.heading3}>{children}</h3>
        </Fade>
      ),
    },
  }

  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.laserContent}>
          {documentToReactComponents(data.laserDesc.json, options)}
        </div>
        <Fade bottom duration={700}>
          <h3>Ukázky laserových prací</h3>
        </Fade>
        <Gallery large={data.large} thumb={data.thumbs} isLaser />
        <Videos data={data.videa} isLaser />
      </div>
    </section>
  )
}

export default LaserContent
